<template>
  <div>
    <h2 class='mb-2 text-lg font-semibold uppercase text-gray-800'>Group by</h2>
    <div class='flex flex-col justify-between'>
      <div class='w-full flex-grow-0 flex-shrink-0'>
      <select
        v-model='group_by_custom_template_id'
        class='form-select w-full'>
        <option
          v-for='template in selectableTemplates'
          :key='`grouping-field-${template.id}`'
          :value='template.id'
          :disabled='isOrganizingCustomField(template.field_type)'>
          {{template.custom_field_name}}
        </option>
      </select>
      </div>
      <div class='w-full flex-grow-0 flex-shrink-0 pt-4'>
        <h3 class='text-base font-semibold text-gray-800'>Show Columns</h3>
        <label v-for='selectableValue in customFieldSelectableValues'
          :key='`selectable-value-${selectableValue}`'
          class='block py-2 text-left text-gray-500 font-light text-sm cursor-pointer hover:bg-gray-200'>
          <input type='checkbox'
            class='form-checkbox mr-1'
            :name='selectableValue'
            :value='selectableValue'
            v-model='group_by_values'>
            <span>{{selectableValue}}</span>
        </label>
        <label class='block py-2 text-left text-gray-500 font-light text-sm cursor-pointer hover:bg-gray-200'>
          <input type='checkbox'
            class='form-checkbox mr-1'
            :name='show_group_by_value_unassigned'
            :value='show_group_by_value_unassigned'
            v-model='show_group_by_value_unassigned'>
            <span>해당없음</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'DashboardViewEditingGroupingBy',
  watch: {
    group_by_custom_template_id: {
      handler: function (newVal) {
        this.group_by_values = (newVal) ? this.customFieldSelectableValues : []
        this.group_by_custom_template = this.customFieldTemplateFromId(newVal)
      }
    }
  },
  computed: {
    ...mapState('customFields', [
      'customFieldTemplates',
    ]),
    ...mapState('dashboardViews', [
      'currentDashboardView',
    ]),
    ...mapGetters('customFields', [
      'customFieldTemplateFromId',
    ]),
    ...mapFields('dashboardViews', [
      'currentDashboardView.group_by_custom_template_id',
      'currentDashboardView.group_by_values',
      'currentDashboardView.group_by_custom_template',
      'currentDashboardView.show_group_by_value_unassigned',
    ]),
    groupBySelectableTypes () {
      return [
        'dropdown',
        'checkbox',
        'multi-select',
        'sections',
        'header'
      ]
    },
    hasCustomFieldTemplates () {
      return this.customFieldTemplates.length > 0
    },
    hasSelectedTemplate () {
      return this.selectedTemplateId !== 0
    },
    groupByCustomTemplate () {
      return this.customFieldTemplateFromId(this.group_by_custom_template_id)
    },
    customFieldSelectableValues () {
      return this.groupByCustomTemplate ? this.groupByCustomTemplate.selectable_values : []
    },
    selectableTemplates () {
      // TODO: figure out contacts, file
      let groupByField = []
      if (this.currentDashboardView.mapping_field_template_ids.length > 0) {
        groupByField = this.customFieldTemplates.filter(template => this.groupBySelectableTypes.includes(template.field_type) && this.currentDashboardView.mapping_field_template_ids.includes(template.id))
      }
      return groupByField
    },
    selectedTemplate () {
      return this.customFieldTemplates.find(template => template.id === this.selectedTemplateId)
    },
  },
  methods: {
    isOrganizingCustomField (fieldType) {
      return fieldType === 'sections' || fieldType === 'header'
    },
  },
}
</script>
